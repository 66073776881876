/* ai message box start */
.ai-message-box {
    width: 100vw;
    height: 100vh;
    background-image: url("./img/msg-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.ai-message-box .ai-message {
    position: relative;
    z-index: 0;
}
.ai-message-box .ai-message .ai-message-title {
    font-size: 1.7rem;
    font-family: Teko-Bold, Teko;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 2rem;
    text-shadow: 0px 0px 20px rgba(0,255,255,0.8);
    text-align: center;
    width: 100%;
    position: absolute;
    z-index: 999;
    top: 3vh;
}
.ai-message-box .ai-message .container {
    width: 90vw;
    height: 90vh;
    background-image: url("./img/msg-ct-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    position: absolute;
    z-index: 111;
    top: 5vh;
    left: 5vw;
    padding-top: 2rem;
}
.ai-message-box .ai-message .container .msg-his-box {
    width: 100%;
}
.ai-message-box .ai-message .container .msg-his-box .msg-his-item {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-family: Barlow-Medium, Barlow;
    color: #FFFFFF;
    line-height: 1.4rem;
}
.ai-message-box .ai-message .container .msg-his-box .msg-his-item .msg-copy {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
}
.ai-message-box .ai-message .container .msg-his-box .item-question {
    text-align: right;
    flex-direction: row-reverse;
}
.ai-message-box .ai-message .container .msg-his-box .item-answer {
    text-align: left;
}
.ai-message-box .ai-message .container .msg-his-box .item-question .msg-copy {
    margin-right: 0.6rem;
}
.ai-message-box .ai-message .container .msg-his-box .item-answer .msg-copy {
    margin-left: 0.6rem;
}
.ai-message-box .ai-message .container .msg-his-box .item-msg-ct {
    padding: 0.2rem 0.4rem;
    max-width: 70%;
}
.ai-message-box .ai-message .container .msg-his-box .item-question .item-question-ct {
    width: auto;
    margin-right: -1px;
    background: linear-gradient(270deg, #12E0E7 0%, #16788F 51%, #175571 100%);
    box-shadow: 0 0 12px 1px #00FFFF;
    border: 1px solid #00FFFF;
    position: relative;
    z-index: 222;
}
.ai-message-box .ai-message .container .msg-his-box .item-question .item-question-ico {
    width: 0;
    height: 0;
    border-top: 0.3rem solid rgba(18, 224, 231, 1);
    border-bottom: 0.3rem solid transparent;
    border-left: 0.3rem solid rgba(18, 224, 231, 1);
    border-right: 0.3rem solid transparent;
    filter: drop-shadow(1px 1px 8px #00FFFF);
    align-self: flex-start;
    margin-right: 0.5rem;
    position: relative;
    z-index: 444;
}
.ai-message-box .ai-message .container .msg-his-box .item-question .item-question-pt {
    width: 0;
    height: 0;
    border-top: 0.2rem solid #FFFFFF;
    border-bottom: 0.2rem solid transparent;
    border-left: 0.2rem solid #FFFFFF;
    border-right: 0.2rem solid transparent;
    filter: drop-shadow(1px 1px 8px #00FFFF);
    position: absolute;
    z-index: 333;
    top: 0;
    left: 0;
}
.ai-message-box .ai-message .container .msg-his-box .item-answer .item-answer-ct {
    width: auto;
    margin-left: -1px;
    background: rgba(0,0,0,0.8);
    box-shadow: 0 0 12px 1px #00FFFF;
    border: 1px solid #FFFFFF;
    position: relative;
    z-index: 222;
}
.ai-message-box .ai-message .container .msg-his-box .item-answer .item-answer-ico {
    width: 0;
    height: 0;
    border-top: 0.3rem solid rgba(0,0,0,1);
    border-bottom: 0.3rem solid transparent;
    border-left: 0.4rem solid transparent;
    border-right: 0.4rem solid rgba(0,0,0,1);
    filter: drop-shadow(1px 1px 8px #00FFFF);
    align-self: flex-start;
    margin-left: 0.5rem;
    position: relative;
    z-index: 444;
}
.ai-message-box .ai-message .container .msg-his-box .item-answer .item-answer-pt {
    width: 0;
    height: 0;
    border-top: 0.2rem solid #FFFFFF;
    border-bottom: 0.2rem solid transparent;
    border-left: 0.2rem solid transparent;
    border-right: 0.2rem solid #FFFFFF;
    filter: drop-shadow(0 0 8px #00FFFF);
    position: absolute;
    z-index: 333;
    top: 0;
    right: 0;
}
/* ai message box end */
